<template>
  <div class="promo">
    <header>
      <b-navbar>
        <template #brand>
          <div class="logo">
            <b-navbar-item> <a href="/">I-ASSISTANT</a></b-navbar-item>
          </div>
        </template>
        <template #start>
          <b-navbar-item href="#future" @click.prevent="scrollToEl('#future')"
            >Возможности</b-navbar-item
          >
          <b-navbar-item href="#price" @click.prevent="scrollToEl('#price')"
            >Тарифы и цены</b-navbar-item
          >
        </template>

        <template #end>
          <b-navbar-item tag="div">
            <div class="buttons">
              <a href="/sign-up" class="button is-primary">
                <strong>Регистрация</strong>
              </a>
              <a href="/workspace" class="button is-light">Личный кабинет</a>
            </div>
          </b-navbar-item>
        </template>
      </b-navbar>
    </header>
    <main>
      <section class="container">
        <div class="columns">
          <div class="hero-body column has-text-centered">
            <h1 class="title">I-ASSISTANT</h1>
            <h1 class="title">Облачная CRM-система для страховых агентов.</h1>
            <h1 class="title">Просто.Безопасно.Надежно.</h1>
            <a href="/sign-up" class="button is-success">Начать бесплатно</a>
          </div>
          <div class="column">
            <iframe
              height="100%"
              width="100%"
              src="https://www.youtube.com/embed/gtMsDFQUjUc?autoplay=0"
              allowfullscreen
            >
            </iframe>
          </div>
        </div>
      </section>
      <section id="future" class="section bg-section container box">
        <h1 class="title has-text-primary">Возможности I-ASSISTANT</h1>
        <p>
          CRM-система I-ASSISTANT создана именно для страховых агентов и на
          данный момент подобных ей не существует. В ней удобно работать
          самостоятельно или подключить сотрудников. Сразу после регистрации в
          I-ASSISTANT Вам будет доступен весь функционал по хранению и изменению
          данных. Заносить и редактировать данные очень просто. Вся информация
          под рукой.
          <b
            >Исключены просрочки полисов Ваших клиентов, так как система сама
            Вам напомнит о полисах с заканчивающимся периодом</b
          >. Вам останется только позвонить Клиенту и подтвердить продление
          полиса.
        </p>
        <br /><br />
        <div class="columns is-multiline is-mobile">
          <div
            class="column is-full-mobile is-half-tablet is-one-third-desktop"
          >
            <div class="card">
              <div class="card-content has-text-centered">
                <p class="title is-5">Удобное хранение данных Страхователя</p>
              </div>
              <footer class="card-footer">
                <p class="card-footer-item subtitle is-6 has-text-centered">
                  Вам больше не надо каждый раз искать данные на компьютере, в
                  вотсапе или еще где-то, звонить Клиенту, просить его скинуть и
                  т.п. I-ASSISTANT позволяет хранить данные в облаке и находить
                  Страхователя по нескольким параметрам в два клика. Все данные
                  хранятся в зашифрованном виде и никто, кроме владельца
                  аккаунта или его сотрудников не увидит их.
                </p>
              </footer>
            </div>
          </div>
          <div
            class="column is-full-mobile is-half-tablet is-one-third-desktop"
          >
            <div class="card">
              <div class="card-content has-text-centered">
                <p class="title is-5">Безопасное хранение документов</p>
              </div>
              <footer class="card-footer">
                <p class="card-footer-item subtitle is-6 has-text-centered">
                  I-ASSISTANT позволяет загружать и хранить сканы документов
                  Страхователя. Документы привязаны к конретному Страхователю.
                  Чтобы получить скан, например, водительского удостоверения,
                  нужен один клик. Все документы хранятся в зашифрованном виде.
                </p>
              </footer>
            </div>
          </div>
          <div
            class="column is-full-mobile is-half-tablet is-one-third-desktop"
          >
            <div class="card">
              <div class="card-content has-text-centered">
                <p class="title is-5">Отслеживание сроков действия полиса</p>
              </div>
              <footer class="card-footer">
                <p class="card-footer-item subtitle is-6 has-text-centered">
                  Больше никаких гневных звонков от Ваших Клиентов, оставшихся
                  на дороге без действуйющего полиса. I-ASSISTANT контролирует
                  полисы, внесенные в систему и за 30 календарных дней
                  предупредит Вас об окончании действия полиса. Вы получаете
                  лояльность со стороны Ваших Клиентов и точно уверены, что
                  никого не забыли и никто от Вас не ушел к другому агенту.
                </p>
              </footer>
            </div>
          </div>
          <div class="column is-full-mobile is-full-desktop is-full-fullhd">
            <div class="card">
              <div class="card-content has-text-centered">
                <p class="title is-5">Мы приводим новых клиентов</p>
              </div>
              <footer class="card-footer">
                <p class="card-footer-item subtitle is-6 has-text-centered">
                  После тестового периода доступно подключение к опции "Новый
                  клиент". Благодаря сотрудничеству со CPA-сетями и опыту работы
                  с рекламой в интернете, у наших пользователей есть возможность
                  получать новых клиентов в своем городе.
                </p>
              </footer>
            </div>
          </div>
        </div>
      </section>
      <section id="price" class="hero">
        <div class="hero-body">
          <TariffsCard />
        </div>
      </section>
      <section class="section bg-section container box">
        <h1 class="title has-text-primary">Почему I-ASSISTANT ?</h1>
        <p class="subtitle is-5 has-text-centered">
          Удобство работы с нами в том, что мы сразу даём максимум функций и
          возможностей в одном ресурсе, оказываем техническую поддержку и
          отвечаем на все возникающие вопросы. Другие плюсы:
        </p>
        <ul class="list-bst heading subtitle is-6">
          <li>Предотвращение просрочек полисов и потери страхователя.</li>
          <li>Внедрение нового инструмента, как конкурентного преимущества.</li>
          <li>
            Возможность продавать и работать полностью «онлайн», а также
            масштабироваться.
          </li>
        </ul>
        <p>
          Чтобы понять, нужны ли мы Вам, предлагаем зарегистрироваться в
          I-ASSISTANT и оценить все возможности лично!
        </p>
      </section>
      <section class="hero">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">Вопрос-ответ</h1>
            <div class="box bg-section">
              <b-collapse
                class="panel"
                animation="slide"
                v-for="(collapse, index) of collapses"
                :key="index"
                :open="isOpen == index"
                @open="isOpen = index"
              >
                <template #trigger="props">
                  <div class="card-header" role="button">
                    <p class="card-header-title">
                      {{ collapse.title }}
                    </p>
                    <a class="card-header-icon">
                      <b-icon :icon="props.open ? 'sort-down' : 'sort-up'">
                      </b-icon>
                    </a>
                  </div>
                </template>
                <div class="card-content">
                  <div class="content">
                    {{ collapse.text }}
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import TariffsCard from '@/components/tariffs/TariffsCard';

export default {
  name: 'Promo',
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          title: 'Для кого предназначена эта платформа?',
          text:
            'I-ASSISTANT создан для страховых агентов, которые не имеют собственных инструментов управления своим бизнесом. Содержать IT-инфрастуктуру сложно и дорого. Поэтому мы создали удобный и надежный сервис для того, чтобы эффективное управление страховым бизнесом стало доступным.',
        },
        {
          title: 'Что умеет I-ASSISTANT?',
          text:
            'Сегодня сервис умеет сохранять данные страхователей, данные полисов страхователей и их автомобилей. Также сохраняет документы страхователей (сканы, фото). Умеет выполнять быстрый поиск страхователя агента по параметрам (по паспорту, или по полису, или по телефону, или по номеру авто). Также система напоминает про истечение сроков действия полиса.',
        },
        {
          title: 'Насколько безопасен ваш сервис?',
          text:
            'Сервис I-ASSISTANT соответствует всем требованиям безопасности, предъявляемым современным облачным продуктам. Данные передаются по зашифрованному протоколу https. Хранение данных ваших страхователей осуществляется в зашифрованном виде по алгоритмам обратного шифрования. Также соблюдено строгое разграничение данных аккаунтов. То есть ваши записи доступны только вам и никому более.',
        },
      ],
    };
  },
  created() {
    this.$store.commit('toggleLoader', false);

    (function (m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function () {
          (m[i].a = m[i].a || []).push(arguments);
        };
      m[i].l = 1 * new Date();
      (k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

    /*global ym*/
    ym(81544822, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
  },
  methods: {
    scrollToEl(id) {
      document.querySelector(id).scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
  components: { TariffsCard },
};
</script>

<style lang="scss" scoped>
.promo {
  background-color: #496ca2;
  background: linear-gradient(to bottom, #3298dc, #496ca2);

  header {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .navbar {
      background-color: transparent;

      .navbar-item,
      .navbar-link {
        font-weight: bold;
        color: #c0dcff;

        &:hover,
        &:focus {
          background-color: transparent;
          color: #fff;
        }
      }
    }

    .logo {
      font-weight: lighter;
      font-size: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 1px;
      color: #fff;

      a {
        color: inherit;
      }

      span {
        font-weight: bold;
        font-size: 22px;
      }
    }
  }

  main {
    margin-top: 100px;

    section {
      margin-bottom: 50px;
    }

    h1 {
      text-align: center;
      color: #fff;
    }

    .hero {
      a:not(.card-header-icon) {
        display: block;
        margin: 50px auto 0;
        max-width: 300px;
        width: 100%;
      }
    }

    .bg-section {
      background-color: #f7f7f7;
    }

    .card {
      height: 100%;
    }

    .list-bst {
      font-weight: bold;

      li {
        position: relative;
        margin-bottom: 10px;
        padding-left: 30px;

        &:before {
          content: '\f00c';
          font-family: 'Font Awesome 5 Free';
          position: absolute;
          top: -2px;
          left: 0;
          font-size: 20px;
          width: 20px;
          height: 20px;
          color: #48c774;
        }
      }
    }
  }

  .titledown {
    font-size: 1rem;
    font-weight: 600;
  }
}
</style>
