<template>
  <div class="container">
    <div class="box">
      <p class="title is-4 has-text-centered">Тарифы и цены</p>
    </div>

    <div class="columns">
      <div class="column">
        <div class="card card-tarifffs">
          <div class="card-content">
            <p class="title is-4 has-text-centered">Стартовый</p>
            <div class="content">
              <p class="is-6">Количество клиентов: до 1000 включительно</p>
              <p class="is-6">Количество сотрудников: неограниченно</p>
              <p class="is-6">Стоимость: 1000 руб в месяц</p>
            </div>
            <a href="/sign-up" class="button is-success">Регистрация</a>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card card-tarifffs">
          <div class="card-content">
            <p class="title is-4 has-text-centered">Профи</p>
            <div class="content">
              <p class="is-6">
                Количество клиентов: от 1001 до 3000 включительно
              </p>
              <p class="is-6">Количество сотрудников: неограниченно</p>
              <p class="is-6">Стоимость: 2000 руб в месяц</p>
            </div>
            <a href="/sign-up" class="button is-success">Регистрация</a>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card card-tarifffs">
          <div class="card-content">
            <p class="title is-4 has-text-centered">Бизнес</p>
            <div class="content">
              <p class="is-6">Количество клиентов: от 3001 и более</p>
              <p class="is-6">Количество сотрудников: неограниченно</p>
              <p class="is-6">Стоимость: 3000 руб в месяц</p>
            </div>
            <a href="/sign-up" class="button is-success">Регистрация</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TariffsCard',
};
</script>

<style lang="scss">
.card-tarifffs {
  height: 100%;

  .card-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .content {
      display: block;
    }
  }

  .button {
    display: block;
    margin-top: auto;
  }
}
</style>
